var currentCover = 0,
    totalCovers = 0,
    animating = false,
    cacheCover = null,
    google,
    mobile,
    sliderTimer,
    sliderSeconds = 6000;

$(document).ready(function(){

    var md = new MobileDetect(window.navigator.userAgent);
    mobile = !(md.mobile()==null && md.tablet()==null && md.phone()==null );

    $(document).foundation(); 

    $(".lang_menu").prepend( $(".lang_menu li." + lang ) );


    $(".simple_menu li a").on("click",function(e){

        var menu =  $(this).parent().parent();
        var in_ = $(this).parent().index();

        if(menu.hasClass("lang_menu")){
            e.preventDefault();
    

            if(in_!=0){
                $(this).parent().parent().prepend( $(this).parent() );
                if(contacto!=""){
                    var slug = $(this).parent().attr("data-slug")
                    window.location.href = $(this).attr("href") + "/" + contacto + "/" + slug;
                }else{
                    window.location.href = $(this).attr("href") + "/" + miembro;
                }
            }
        }

        if(menu.hasClass("members_menu")){
            if(in_!=0){

            }else{
                e.preventDefault();
            }
        }

        if(menu.hasClass("contacts_menu")){
            e.preventDefault();
            if(in_!=0){
                var name = $(this).attr('title').toLowerCase(); 
                var url_ =  $(".inicio a").attr("href") + "/" + name  + "/" + contacto_slug;
                window.location.href = url_; 
            }
        }

        $(".simple_menu").removeClass("selected");
        $(this).parent().parent().addClass("selected");
        $(".simple_menu").not(".selected").removeClass("opened");
        $(this).parent().parent().toggleClass("opened");

    });

    if(contacto!=""){

        $(".inicio a").on("click",function(e){
            e.preventDefault();
            if(contacto == "costaisa_group"){
                contacto = "costaisa";
            }
            window.location.href = $(this).attr("href") + "/" + contacto; //contacto_slug;
        });
    
        prepareBottomContactMenu();
        loadContacto(contacto);

    }else{


        $(".nav_slider a").on("click",function(e){
            e.preventDefault();
            $(".nav_slider a").removeClass("selected");
            $(this).addClass("selected");
            gotoCover(0, $(this).parent().index() );   
        });


        prepareCovers();
        loadCurrentCover();
    }

    removeHover();

    $(window).on("resize",resize); 
    resize();

});


function removeHover(){

    var touch = 'ontouchstart' in document.documentElement
                || navigator.maxTouchPoints > 0
                || navigator.msMaxTouchPoints > 0;
    
    if (touch) { 
        try { 
            for (var si in document.styleSheets) {
                var styleSheet = document.styleSheets[si];
                if (!styleSheet.rules) continue;
    
                for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
                    if (!styleSheet.rules[ri].selectorText) continue;
    
                    if (styleSheet.rules[ri].selectorText.match(':hover')) {
                        styleSheet.deleteRule(ri);
                    }
                }
            }
        } catch (ex) {}
    }

}

function prepareBottomContactMenu(){

    $(".menu_contacto li").removeClass("selected");
    $(".menu_contacto li."+miembro).addClass("selected");


    $(".menu_contacto li").each(function(){
        $(this).find("a").on("click",function(e){
            e.preventDefault();
            if(!$(this).parent().hasClass("selected")){
                var name = $(this).parent().attr('class'); 
                window.location.href = $(".inicio a").attr("href") + "/" + name + "/" + "contact"  ;
            }
        });
    });

}

function prepareCovers(){
    
    currentCover = $(".covers .cover-list li[data-name=" + miembro + "]").index();
    totalCovers = $(".covers .cover-list li").length;
        
    $(".nav_slider a").eq(currentCover).addClass("selected");

    $(".covers .cover-list li").each(function(){
        var name = $(this).attr('data-name');
        $(this).find(".member_logo").attr("src",url + '/assets/img/logos/white/' + name + '.svg');
    });

    $(".left-arrow, .right-arrow").on("click",function(){
        gotoCover($(this).attr("data-dir"));
    });

    $(".covers").touch();
    $(".covers").on('swipeLeft', function(event) {
        if(mobile || $(window).width()<=640){ gotoCover(1); }
    });
    $(".covers").on('swipeRight', function(event) {
        if(mobile || $(window).width()<=640){ gotoCover(-1); }
    });


    $(".covers").mouseover(function(){
        setSliderTimer(true);
    });

    $(".covers").mouseout(function(){
        setSliderTimer();
    });

    setSliderTimer();

}

function setSliderTimer(destroy){

    if(destroy){ 
        clearInterval(sliderTimer);
        return; 
    }

    clearInterval(sliderTimer);
    sliderTimer = setInterval(function(){
        $(".right-arrow").trigger("click");
    },sliderSeconds);

}

function gotoCover(dir,index){

    $("html, body").stop().animate({scrollTop:0}, 500, 'swing', function() { });

    if( index==currentCover ) return;

    if( animating ){
        if(index!=undefined){
            cacheCover = index;
        }
        return;
    }

    if( cacheCover !== null){
        index = cacheCover;
        cacheCover = null;
    }

    animating = true;
    var w = $(window).width();

    if(index===undefined){
        dir = Number(dir);
        var nDir = currentCover + dir;
        if( nDir >= totalCovers){
           nDir = 0;
        }else if(nDir<0){
            nDir = totalCovers-1; 
        }
    }else{
        nDir = index;
        dir = "1";
    }

    var duration = (w<640)?500:1000;

    var nCover = $(".covers .cover-list li").eq(nDir);
    var name = nCover.attr('data-name');

    $(".covers").css('background-image','none');

    var imgUrl = url + 'assets/img/covers/' + name + '.jpg';


    var pic = new Image();
    pic.src = imgUrl;
    pic.onload = function() {
        $(".bg").fadeTo( 250, 0, function(){
            $(".bg").css("background-image","url(" + imgUrl + ")" );
            $(".bg").fadeTo( 1000, 1);
        });
    };

    var cCover = $(".covers .cover-list li.selected");
    cCover.animate({ left: dir*-w }, { duration: duration, easing: 'swing'});

    var slug =  lang  + "/" + nCover.attr('data-name');
    window.history.replaceState( '', '', url + slug );

    if( $(".members_menu li").eq(0).attr("data-index") != nDir ){ 
        $(".nav_slider a").removeClass("selected");
        $(".nav_slider a").eq(nDir).addClass("selected");

        
    }


    nCover.show().css("left", dir*w + "px").animate(
        { left:0}, 
        { duration: duration, easing: 'swing', 
            complete:function(){
                animating = false;
                if( cacheCover !== null){
                    gotoCover(0,cacheCover);
                }
            }
        }
    );

    cCover.removeClass("selected");
    nCover.addClass("selected");

    currentCover = nDir;


}

function loadCurrentCover(){

    $(".covers .cover-list  li.selected").removeClass("selected");
    $(".covers .cover-list  li[data-name="+miembro+"]").addClass("selected");
    var name = miembro; //$(".covers li.selected").attr('data-name');
    $(".covers").css('background-image','url(' + url + 'assets/img/covers/' + name + '.jpg)');
    $(".covers .cover-list  li.selected").show();

}

function loadContacto(id){

    $(".direcciones li").on("click",function(){
        $( '#googlemaps' ).attr( 'src',"" );
        $( '#googlemaps' ).attr( 'src',"https://www.google.com/maps/embed?pb=" +  $(this).attr("data-map") );
        $("html, body").stop().animate({scrollTop:$('#googlemaps').offset().top }, 500, 'swing', function() { });
    });
}

var timeoutIframeRefresh;

function resize(){
    var w = $(window).width();

    if(contacto==""){
        var l = $(".covers .cover-list  li").length;
        $(".covers ul.cover-list").width(w);
        var lih = 0;
        $(".covers .cover-list li").each(function(){
            if($(this).height()>lih){
                lih = $(this).height();
            }
            if($(this).hasClass("selected")){
                $(this).css("left","0px");
            }else{
                $(this).css("left",w+"px");
            }
        }); 
        if(w>640){
            $(".covers").height(lih+30);
        }else{
            $(".covers").height(lih+30);
        }
    }else{

        if(w>640){
             
            if($(".ficha").outerHeight()>500){  
                $(".c_").css("min-height", $(".ficha").outerHeight() + "px" );
                $(".googlemaps").css("min-height", $(".ficha").outerHeight() + "px");
            }else{
                $(".c_").css("min-height", "500px" );
                $(".googlemaps").css("height", "500px");
            }
        }else{  

            $(".c_").css("min-height", "initial" );
            $(".googlemaps").css("min-height", "300px"); 
            $(".googlemaps").css("height", "300px"); 
        }

        clearTimeout(timeoutIframeRefresh);
        timeoutIframeRefresh = setTimeout(function(){

           
        },500);

    }

}